import React, { useContext } from 'react';
import Image from 'next/image';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import { AppContext } from '@/contexts/AppContext';
import { SidebarItem } from './SideBarItem';
import { useLogout } from '@/hooks/useLogout';
import { useRouteName } from '@/hooks/useRoute';
import { useLanguage } from '@/hooks/useLanguage';
import { useAppRoutes } from '@/hooks/useAppRoutes';
import { 
  Drawer, 
  Divider, 
  List, 
  ListItemIcon, 
  Box, 
  Tooltip, 
  IconButton
} from '@mui/material';
import { Route } from '@/interfaces/IRoute';
import { useUserSession } from '@/hooks/useUserSession';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar,
}));

const Sidebar: React.FC = () => {
  const { getRoleBasedRoutes } = useAppRoutes();
  const roleBasedRoutes = getRoleBasedRoutes();
  
  const { lang } = useLanguage()
  const { navOpen, setNavOpen } = useContext(AppContext);
  const theme = useTheme();
  const { routeName } = useRouteName();
  const { handleLogout } = useLogout()

  const drawerWidth: number = navOpen ? 200 : 60;
  const handleDrawerClose = () => setNavOpen(false);

  const { user } = useUserSession()

  return (
    <Box className={`${!navOpen && 'hidden md:flex'}`}>
      <Drawer
        variant={navOpen ? 'persistent' : 'permanent'}
        anchor="left"
        open={navOpen}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            overflow: 'hidden'
          }
        }}
        className={`w-full ${navOpen ? 'w-full md:w-52' : 'w-0 md:w-14'}`}
        PaperProps={{
          className: "bg-secondary dark:bg-slate-700 border-0"
        }}
      >
        <DrawerHeader>
          {navOpen ? 
            <Box className="flex items-center justify-between w-full">
              <Image 
                src="/images/logo.svg" 
                height={35.75} 
                width={110} 
                alt="Cogni2" 
                className="filter invert brightness-0" 
                priority 
              />
              <Tooltip title={lang('close.menu')} arrow>
                <IconButton onClick={handleDrawerClose} className="text-white">
                  {theme.direction === 'ltr' ? <ChevronLeftIcon className="text-2xl" /> : <ChevronRightIcon className="text-2xl" />}
                </IconButton>
              </Tooltip>
            </Box>
          : <Tooltip title="Cogni2" arrow>
              <Box className="pt-0.5 w-full m-auto ml-2">
                <Image 
                  src="/images/cogni2-icon.png" 
                  alt="Cogni" 
                  width={40} 
                  height={34.20}  
                  priority
                  className='object-cover dark:filter dark:invert dark:brightness-0' 
                />
              </Box>
            </Tooltip>
          }
        </DrawerHeader>
        <Divider className="border-tertiary dark:border-slate-900 opacity-35" />
        <List disablePadding>
          {roleBasedRoutes?.map((route: Route, index: number) => {
            const baseRoute: string = route?.url?.split('/')?.[1] || '';
            const isActive: boolean = routeName?.replace('/', "")?.startsWith(baseRoute) || false

            // WORKAROUND: Habilita o inbox apenas no workspace Atlantica
            const isWorkspaceEnabled: boolean = user.workspace.alias === 'rpinn' || user.workspace.alias === 'cogni2';
            if (!route.url || (!isWorkspaceEnabled && (route.url.startsWith('/inbox')))) {
              return null;
            }

            // WORKAROUND: Habilita o live apenas no workspace Cogni2
            if (!route.url || (user.workspace.alias !== 'cogni2' && (route.url.startsWith('/live')))) {
              return null;
            }

            return (
              <SidebarItem
                key={index}
                route={route}
                active={isActive}
              />
          )})}
        </List>

        <List disablePadding className="mt-auto">
          <ListItemIcon className="text-white mb-5 w-full">
            <SidebarItem 
              text={lang('logout')}
              onClick={handleLogout} 
              icon={<LogoutIcon className="text-xl" />}
              active={false} 
            />
          </ListItemIcon>
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
