import React, { useState, useContext, useMemo, ReactElement } from 'react';
import { Box, Button, CircularProgress, Container, Drawer, IconButton, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { TicketsContext } from '@/contexts/TicketsContext';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import InboxIcon from '@mui/icons-material/Inbox';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import { ITicketAll } from '@/interfaces/ticketInterface';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CachedIcon from '@mui/icons-material/Cached';
import { TicketsLiveList } from './TicketsLiveList';

interface TabPanelProps {
  children?: ReactElement;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      className="m-0"
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number, isActive: boolean) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
    className: `flex flex-row gap-1 items-center justify-center flex-1 text-center normal-case font-light text-small whitespace-nowrap py-0 min-h-9 border border-solid rounded mr-1 transition
      ${isActive ? "bg-secondary !text-white" : "bg-gray-50 text-gray-700 dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700"}
      hover:font-normal hover:border-secondary/50 hover:text-secondary disabled:text-gray-300 border-gray-200`,

  };
}

const drawerWidth: number = 380;

type LiveDrawerProps = {
  open: boolean;
  onClose: () => void;
}

export function LiveDrawer({ open, onClose }: LiveDrawerProps) {
  const [tab, setTab] = useState<number>(0)
  const { 
    tickets, 
    exportTickets,
    loading, 
    refetchTickets, 
    isRefetchingTickets,
    setOnlyActiveTickets, 
    setTodayTickets,
    setInboxTickets,
    setPage,
    pageSize
  } = useContext(TicketsContext);

  useMemo(() => {
    if(open){
      setTodayTickets(true);
      setInboxTickets(false);
      setOnlyActiveTickets(false);
      setTab(0);
    }
  }, [open, setTodayTickets]);

  const handleOnlyActiveTickets = (e) => {
    e.preventDefault()
    e.stopPropagation();

    setOnlyActiveTickets(prevState => !prevState)
    setTodayTickets(false)
    setInboxTickets(false)
    setPage(1)
  }

  const handleTodayTickets = (e) => {
    e.preventDefault()
    e.stopPropagation();

    setTodayTickets(prevState => !prevState)
    setInboxTickets(false)
    setOnlyActiveTickets(false)
    setPage(1)
  }

  const handleInboxTickets = (e) => {
    e.preventDefault()
    e.stopPropagation();

    setInboxTickets(prevState => !prevState)
    setOnlyActiveTickets(false)
    setTodayTickets(false)
    setPage(1)
  }

  const handleTabChange: (e: React.SyntheticEvent,newValue: number) => void = (e: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)

    switch (newValue) {
      case 0:
        handleTodayTickets(e);
        break;
      case 1:
        handleOnlyActiveTickets(e);
        break;
      case 2:
        handleInboxTickets(e);
        break;
      default:
        break;
    }  
  }

  const visibleRows: ITicketAll[] = useMemo(() => {
    return tickets?.data || []
  }, [tickets?.data, isRefetchingTickets])

  const totalTickets: number = visibleRows?.length || 0;
  const totalTicketsText: string = totalTickets >= pageSize ? '['+totalTickets+'+]' : '['+totalTickets.toString()+']';

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          position: 'fixed',
        },
      }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Container className="relative p-0 w-full">
        <Box id="live-actions" className="sticky z-10 top-0 p-0 w-auto bg-white dark:bg-slate-800">
          <Toolbar className="flex items-center justify-between gap-1 mb-2 w-full min-h-15 bg-gray-100 dark:bg-slate-700 border-solid border-0 border-l-4 border-gray-200 dark:border-slate-900">
            <div className="flex items-center justify-start">
              <span className="relative flex h-4 w-4 items-center justify-center mr-1">
                <span className="bg-green-400 animate-ping duration-800 absolute inline-flex h-full w-full rounded-full opacity-50"></span>
                <span className="bg-green-500 relative inline-flex rounded-full h-2 w-2 opacity-75"></span>
              </span>
              <Typography variant="h6" noWrap className="text-mdSmall text-gray-700 dark:text-slate-200 uppercase">
                Atendimentos em Tempo Real
              </Typography>
              {loading && <CircularProgress size="16px" className="animate-spin ml-2 text-gray-500 dark:text-slate-200" />}
            </div>
            <IconButton onClick={onClose} className="ml-auto">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="dashboard tabs"
            allowScrollButtonsMobile
            variant="fullWidth"
            className="p-2"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <Tab
              label={`Hoje ${Boolean(tab === 0 && !loading) ? totalTicketsText : ''}`}
              disabled={loading}
              icon={<WhatsappIcon className="!m-0 text-xs" />}
              {...a11yProps(0, tab === 0)}
            />
            <Tab
              label={`Tickets Ativos ${Boolean(tab === 1 && !loading) ? totalTicketsText : ''}`}
              disabled={loading}
              icon={<NotificationsActiveIcon className="!m-0 text-xs" />}
              {...a11yProps(1, tab === 1)}
            />
            <Tab
              label={`Inbox ${Boolean(tab === 2 && !loading) ? totalTicketsText : ''}`}
              disabled={loading}
              icon={<InboxIcon className="!m-0 text-xs" />}
              {...a11yProps(2, tab === 2)}
            />
          </Tabs>
        </Box>
        <Box className="p-3 pt-0 pr-1">
          <TabPanel value={tab} index={0}>
            <TicketsLiveList 
              index={0} 
              rows={visibleRows} 
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <TicketsLiveList 
              index={1} 
              rows={visibleRows} 
            />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <TicketsLiveList 
              index={2} 
              rows={visibleRows} 
            />
          </TabPanel>
          
          {!loading && 
            <Box className="flex items-center justify-between p-4 mt-auto">
              <Button 
                type="button"
                variant="contained"
                size="medium"
                disabled={isRefetchingTickets}
                onClick={() => exportTickets()}
                className="min-w-auto normal-case text-xs font-light gap-1"
              >
                <FileDownloadIcon/> Exportar Tickets
              </Button>

              <Button 
                type="button"
                variant="outlined"
                size="medium"
                disabled={isRefetchingTickets}
                onClick={refetchTickets}
                className="min-w-auto normal-case text-xs font-light gap-1"
              >
                <CachedIcon/> Atualizar
              </Button>
            </Box>
          }
        </Box>
      </Container>
    </Drawer>
  );
}
