import React, { useContext, ReactElement } from 'react';
import { Alert, Avatar, Box, Button, Chip, List, ListItem, Skeleton, Tooltip, Typography } from '@mui/material';
import { ITicketAll } from '@/interfaces/ticketInterface';
import { diffForHumans, formatPhoneNumber, getNameAbbr, markdownToHtml, stripHtmlTags } from '@/helpers/utils';
import { useRouter } from "next/navigation";
import { AppContext } from '@/contexts/AppContext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { format } from 'date-fns';
import { useLanguage } from '@/hooks/useLanguage';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { TicketsContext } from '@/contexts/TicketsContext';
import ReportIcon from '@mui/icons-material/Report';
import { QuestionArgsMessageTypeEnum, SessionStatusEnum } from '@/enum/TicketEnum';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

type ITicketsLiveListProps = {
  rows: ITicketAll[] | undefined;
  index: number;
}

export const TicketsLiveList = ({ rows, index }: ITicketsLiveListProps) => {
  const { setNavLoading } = useContext(AppContext)
  const { pageSize, loading, isRefetchingTickets } = useContext(TicketsContext);
  
  const { lang, currentLang } = useLanguage()
  const router = useRouter()

  const handleClickOpenDetailTicket = (event: React.MouseEvent<unknown>, id: string) => {
    event.preventDefault();
    event.stopPropagation();

    const url = `/tickets/ticket/${id}`;
    if (event.shiftKey || event.ctrlKey || event.metaKey) {
      window.open(url, '_blank');
    } else {
      setNavLoading?.(true);
      router.push(url);
    }
  };

  const isLoading: boolean = loading || isRefetchingTickets;

  return (
    <List className="p-0" id={`tickets-list-${index}`}>
      {isLoading && (
        [...Array(pageSize)].map((_, skeletonIndex) => (
          <ListItem key={skeletonIndex} className="px-0 py-1.5 w-full border-0 border-solid border-b border-slate-200 dark:border-slate-700">
            <Box className="flex items-center justify-start gap-3 w-full">
              <Box className="customer relative">
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
              <Box className="content w-full">
                <div className="time">
                  <Skeleton variant="text" width="50%" height={20} />
                </div>
                <div className="flex items-center gap-1">
                  <Skeleton variant="text" width="40%" height={20} />
                </div>
                <Skeleton variant="text" width="80%" height={20} />
                <div className="flex items-center justify-between mt-2">
                  <Skeleton variant="rectangular" width={80} height={20} />
                  <Skeleton variant="circular" width={24} height={24} />
                </div>
              </Box>
            </Box>
          </ListItem>
        )))
      }

      {(rows && rows?.length > 0) ? rows?.map((row: ITicketAll, index: number) => {
        const agentTicket: string = row?.user?.first_name
          ? `${row.user.first_name} ${row.user?.last_name}`
          : 'Sem agente';

        const isNotClosed: boolean = 
          row.status.slug !== SessionStatusEnum.FINISHED && 
          row.status.slug !== SessionStatusEnum.RESOLVED;

        const isQuestionTemplate: boolean = row?.question?.args?.message_type === QuestionArgsMessageTypeEnum.TEMPLATE
        const isLateResponse: boolean = isNotClosed && Boolean(row?.question.updated) && !isQuestionTemplate && !row?.question.response;
        
        return (
          <ListItem key={row.id} id={`ticket-item-${index}`} className="px-0 py-1.5 w-full border-0 border-solid border-b border-slate-200 dark:border-slate-700">
            <Button
              type="button"
              onClick={(e) => handleClickOpenDetailTicket(e, row?.id || '')}
              className="group min-w-auto normal-case flex items-center justify-start text-left gap-4 w-full"
            >
              <Box className="flex items-center justify-start gap-3 w-full">
                <Box className="customer relative">
                  <WhatsAppIcon className="text-xs text-secondary absolute group-hover:hidden" />
                  <div className='w-10 h-10 text-sm bg-secondary/10 group-hover:bg-secondary text-secondary group-hover:text-white dark:bg-slate-900 dark:text-slate-300 flex items-center justify-center rounded-full transition duration-300'>
                    {row?.customer?.name ? getNameAbbr(row?.customer?.name) : <PersonOffIcon />}
                  </div>
                </Box>
                <Box className="content w-full">
                  <div className="time">
                    <Typography 
                      component="small" 
                      className="text-xs font-light text-gray-600 inline-flex items-center gap-1"
                    >
                      <AccessTimeIcon className="text-xs font-light text-black" /> 
                      <small>
                        {format(new Date(row?.updated), "hh:mm")} » {diffForHumans(row?.updated, true, currentLang)} » #{row?.ticket_number?.split('_')?.[2]} » <span className="text-primary">{row?.questionsSize || 0} mensagens</span>
                      </small>
                    </Typography> 
                  </div>
                  <div className="flex items-center gap-1">
                    <Typography component="span" className="text-xs font-semibold text-secondary">
                      {row?.customer?.name || <><PersonOffIcon className="text-red-400" /> <span className="text-red-400 font-medium text-small">Usuário sem nome</span></>}
                    </Typography>
                    {row?.customer?.phone && <Typography component="small" className="text-xs font-light text-gray-600"> » {formatPhoneNumber(row.customer.phone)}</Typography>}
                  </div>

                  {isQuestionTemplate ? 
                    <span className="font-medium text-small text-orange-800 dark:text-slate-200">
                      <MarkEmailReadIcon className="text-sm" /> {lang('template.sent')} {row.question?.args?.message_type_description}
                    </span>
                  : 
                    <div className="question mt-1">
                      {row.question?.question_sent && 
                        <Tooltip 
                          title={
                            <div>
                              {row.question?.question_sent && <p><strong>Pergunta:</strong> {stripHtmlTags(row.question?.question_sent)}</p>}
                              {row.question?.response && <p><strong>Resposta:</strong> {stripHtmlTags(row.question?.response)}</p>}
                            </div>
                          }                  
                          arrow 
                          disableInteractive
                        >
                          <div className="text-xs font-light">
                            {row.question?.question_sent && <p className="text-gray-700 font-medium line-clamp-2 m-0 mb-1 remove-empty" dangerouslySetInnerHTML={{ __html: markdownToHtml(`» ${row.question?.question_sent}`) }} />}
                            {row.question?.response && <span className="leading-3 line-clamp-3 text-small text-gray-700" dangerouslySetInnerHTML={{ __html: markdownToHtml(`${row.question?.response}`) }}></span>}
                            {isLateResponse && <p className="p-0 text-small mt-1 text-red-500 flex items-center gap-1"><ReportIcon className="text-sm align-middle" /> Resposta em atraso: {diffForHumans(row.question.updated, true, currentLang)}</p>}
                          </div>
                        </Tooltip>
                      }
                    </div>
                  }
                  <div className="flex items-center justify-between mt-2">
                    <Chip 
                      label={row.status.title} 
                      icon={<span className="text-xs scale-90 align-baseline mt-1">{row.status.icon as ReactElement}</span>} 
                      variant="filled" 
                      size="small"
                      className={`text-small font-light ${row.status.className}`}
                    />
                    <Chip
                      avatar={<Avatar alt={agentTicket} src={row?.user.photo} />}
                      label={agentTicket}
                      variant="outlined"
                      size="small"
                      className="text-small font-light text-slate-500 dark:text-slate-200"
                    />
                  </div>
                </Box>
              </Box>
            </Button>
          </ListItem>
        )}
      )
      : <>{!isLoading && <Alert severity="info" className="text-xs">Nenhum ticket encontrado</Alert>}</>
      }
    </List>
  );
}