'use client'
import { useContext, useState, MouseEvent } from 'react';
import Image from 'next/image';
import { AppContext } from '@/contexts/AppContext';
import AppBar from '@mui/material/AppBar';
import { CardUser } from '@/components/base/CardUser';
import LogoutIcon from '@mui/icons-material/Logout';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useRouter } from 'next/navigation';
import { LiveDrawer } from '@/components/widgets/LiveDrawer';
import { 
  Box, 
  Toolbar,
  Tooltip,
  Typography,
  Container,
  Popover,
  Button,
  Paper,
  Divider,
  ListItem,
  ListItemButton,
  LinearProgress,
  List,
  IconButton
} from '@mui/material';
import { useLogout } from '@/hooks/useLogout';
import { useLanguage } from '@/hooks/useLanguage'
import { NavBarActions } from '@/components/modules/layouts/NavBarActions';
import { useUserSession } from '@/hooks/useUserSession';
import { diffForHumans } from '@/helpers/utils';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useAppRoutes } from '@/hooks/useAppRoutes';
import LiveTvIcon from '@mui/icons-material/LiveTv';

export function NavBar() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [openLiveDrawer, setOpenLiveDrawer] = useState<boolean>(false)
  const router = useRouter()

  const { user, signed_in } = useUserSession()
  const { setNavOpen, navLoading } = useContext(AppContext)
  const { handleLogout } = useLogout()
  const { lang, FormattedLang, currentLang } = useLanguage()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if(anchorEl){
      setAnchorEl(null);
    }else{
      setAnchorEl(event?.currentTarget);
    }
  }

  const handleClose = () => setAnchorEl(null)

  const open: boolean = Boolean(anchorEl);
  const id: string = open ? 'user-popover' : '';
  const { userRole } = useAppRoutes();

  const handleOpenLiveTickets = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpenLiveDrawer(true)
  }

  return (
    <AppBar className='h-[60px] bg-gray-100 dark:bg-slate-700 justify-center' position="static" elevation={0}>
      { navLoading ? <LinearProgress className="h-1 absolute w-full left-0 right-0 top-0 z-5" /> : ''}
      <Container maxWidth={false}>
        <Toolbar className="md:mx-4 justify-between w-full" disableGutters>
          <Box className="flex items-center">
            <Tooltip title={lang("menu.open")} arrow>
              <Button className="min-w-auto no-dialog p-0 md:ml-10 mr-4 text-gray-400 dark:text-gray-300" onClick={() => setNavOpen(true)}>
                <Image 
                  src="/images/icons/burger.svg" 
                  width={17}
                  height={17}
                  alt="Menu Toggle" 
                  className="no-print dark:filter dark:invert dark:brightness-0 hover:filter-secondary-color transition duration-100" 
                  priority 
                />
              </Button>
            </Tooltip>
            <Box className="flex justify-start">
              <div className="flex md:hidden">
                <Tooltip title={user?.workspace?.name || "Cogni2"} arrow>
                  <Image 
                    src={user?.workspace?.logo || "/images/cogni2.svg"}
                    height={60} 
                    width={50} 
                    alt={user?.workspace?.name || "Cogni2"}
                    className="dark:filter dark:invert dark:brightness-0" 
                    style={{ maxHeight: '60px', width: 'auto' }} 
                    priority 
                  />
                </Tooltip>
              </div>
              <div className="hidden md:flex">
                <Tooltip title={user?.workspace?.name || "Cogni2"} arrow>
                  <Image 
                    src={user?.workspace?.logo || "/images/logo.svg"}
                    height={45} 
                    width={140} 
                    style={{ maxHeight: '45px', width: 'auto' }} 
                    alt={user?.workspace?.name || "Cogni2"}
                    className="dark:filter dark:invert dark:brightness-0" 
                    priority 
                  />
                </Tooltip>
              </div>
            </Box>
          </Box>

          <Box className="flex items-center h-full">
            <NavBarActions className="hidden md:flex" />
            <div className="relative border-solid border-0 border-x border-white dark:border-slate-800 pl-6">
              <Button 
                type="button"
                onClick={handleClick} 
                className="no-dialog md:mr-2 ml-0 pl-0 normal-case min-w-auto"
                aria-describedby={id}
              >
                {userRole && 
                  <CardUser 
                    user={user} 
                    role={user?.workspace.name || user?.workspace.alias || lang(`role.${userRole}` || 'loading')}
                    responsiveOnlyThumb
                  />
                }
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                className="max-w-full w-full"
              >
                <Paper 
                  sx={{
                    minWidth: 200,
                    width: '100%',
                    padding: 2,
                  }}
                  className="dark:text-slate-200 dark:bg-slate-900 pb-2"
                >
                  <Tooltip title={`Perfil desse usuário: ${userRole}`} arrow disableInteractive>
                    <div className="flex items-center justify-start gap-1 mb-2">
                      <Typography variant="h5" className="text-small uppercase text-slate-500 font-medium">
                        <FormattedLang id="profile" /> <small>/</small> 
                      </Typography>
                      <Typography variant="h6" className="text-small uppercase text-slate-500 font-medium">
                        <FormattedLang id={`role.${userRole}` || user?.workspace.name || user?.workspace.alias || 'loading'} />
                      </Typography>
                    </div>
                  </Tooltip>
                  {userRole && 
                    <CardUser 
                      user={user}
                      role={user?.workspace.name || user?.workspace.alias || lang(`role.${userRole}` || 'loading')}
                    />
                  }
                  <Divider className="mt-3 mb-2 dark:border-slate-700" />
                  <List>
                    <ListItem disablePadding className="block">
                      <ListItemButton onClick={() => router.push('/profile')} className="flex items-center gap-1 px-2 text-xs text-slate-600 dark:text-slate-200 font-light no-underline hover:bg-gray-100 dark:hover:bg-slate-800 transition py-2 no-dialog">
                        <ManageAccountsIcon className="text-xs" /> <FormattedLang id="edit.account" />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding className="block">
                      <ListItemButton onClick={() => router.push('/change_password')} className="flex items-center gap-1 px-2 text-xs text-slate-600 dark:text-slate-200 font-light no-underline hover:bg-gray-100 dark:hover:bg-slate-800 transition py-2 no-dialog">
                        <VpnKeyIcon className="text-xs" /> <FormattedLang id="change.password" />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding className="block">
                      <ListItemButton onClick={handleLogout} className="flex gap-1 px-2 text-xs text-slate-600 dark:text-slate-200 font-light hover:bg-gray-100 dark:hover:bg-slate-800 transition py-2 no-dialog">
                        <LogoutIcon className="text-xs" /> <FormattedLang id="logout" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                  <NavBarActions className="flex md:hidden" />
                  {signed_in ? 
                    <>
                      <Divider className="border-gray-100 dark:border-slate-700 mb-2 mt-1" />
                      <Typography variant="h6" className="flex items-center justify-center text-center text-small uppercase text-gray-400 dark:text-slate-200 font-light p-0 m-0">
                        <small className="gap-0.5 flex items-center">
                          <AccessTimeIcon className="text-small" />
                          <FormattedLang id="signed_in" /> {diffForHumans(signed_in, false, currentLang)}
                        </small>
                      </Typography>
                    </>
                  : ''}
                </Paper>
              </Popover>
            </div>
            <div className="live">
              <IconButton 
                aria-label="Live Tickets"
                onClick={handleOpenLiveTickets} 
                className="-ml-2 no-dialog normal-case hover:text-secondary hover:bg-transparent transition hover:scale-110 origin-center relative h-full"
              >
                <span className="relative flex h-4 w-4 items-center justify-center translate-x-8 -translate-y-2">
                  <span className="bg-green-400 animate-ping duration-800 absolute inline-flex h-full w-full rounded-full opacity-50"></span>
                  <span className="bg-green-500 relative inline-flex rounded-full h-2 w-2 opacity-75"></span>
                </span>
                <LiveTvIcon className="text-xl" />
              </IconButton>
              <LiveDrawer 
                open={openLiveDrawer} 
                onClose={() => setOpenLiveDrawer(false)} 
              />
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
